<div class="contactContainerDiv">
  <div class="icoContainer">
    <a href={{phoneNumber}}><img class="icoImg" src={{phoneIcon}} alt=""></a>
  </div>
  
  <div class="icoContainer">
    <a href={{emailAddress}} target="_blank"><img class="icoImg" src={{emailIcon}} alt=""></a>
  </div>
  
  <div *ngFor="let link of links" class="icoContainer">
      <a  href={{link.url}} target="_blank"><img class="icoImg" src={{link.icon}} alt=""></a>
  </div>
</div>

