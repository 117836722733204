<div class='jobDivContainer'>

    <h1 class= 'jobTitle' >{{jobTitleLable}}</h1>

    <div class="jobListContainerDiv">
        <div class= 'jobList' *ngFor="let job of jobList">
            <div class="jobHeader">
                <p class= "jobHeaderEnterprise">{{job.enterprise}}</p>
                <p class= "jobHeaderDates">{{job.since}} ~ {{job.until}}</p>
            </div>
            <p class="jobSummary">{{job.summary}}:</p>
            <pre class="jobDescription">{{job.description}}</pre>
        </div>
   </div>
</div>




