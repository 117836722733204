<div class="formContactContainerDiv">
  <h1 class="email-form-title">{{lblContactTitle}}</h1>

  <div *ngIf="showLoadingSpinner" class="loadingSpinner formContainerDiv">
    <mat-spinner></mat-spinner>
    <h1 class="email-loading-title">{{lblEmailLoadingTitle}}</h1>
  </div>

  <div *ngIf="showEmailAlert" class="mailResponseAlert formContainerDiv">
    <div class="sendMailProcess">

      <div *ngIf="!showEmailSendFail">
        <h1 class="sendMailProcessMesageOk">{{lblEmailProcessMsgOk}}</h1>
      </div>

      <div *ngIf="showEmailSendFail">
        <h1 class="sendMailProcessMesageErr">{{lblEmailProcessMsgErr}}</h1>
      </div>

      <div>
        <button mat-flat-button (click)="aceptMessage()">{{btnAceptMessage}}</button>
      </div>
    </div>
</div>

  <div *ngIf="showEmailForm" class="formContainerDiv">
    <form class="example-form">
      <mat-form-field class="example-full-width">
        <mat-label>{{lblEmail}}</mat-label>
        <input matInput onchange="" [formControl]="emailFormControl" [errorStateMatcher]="matcher" placeholder={{placeHolderEmail}} [(ngModel)]="mailInput"/>
        <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
          {{errEmail}}
        </mat-error>
        <mat-error *ngIf="emailFormControl.hasError('required')">
          {{tooltipEmail1}} <strong>{{tooltipEmail2}}</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <mat-label>{{lblSummary}}</mat-label>
        <input matInput [formControl]="summaryFormControl" placeholder={{placeHolderSummary}} [(ngModel)]="summaryInput"/>
        <mat-error *ngIf="summaryFormControl.hasError('required')">
          {{tooltipEmail1}} <strong>{{tooltipEmail2}}</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <mat-label>{{lblMessage}}</mat-label>
        <textarea matInput [formControl]="messageFormControl" placeholder={{placeHolderMessage}} [(ngModel)]="massageTextArea"
        cdkTextareaAutosize
        cdkAutosizeMinRows="7"
        cdkAutosizeMaxRows="20"></textarea>
        <mat-error *ngIf="messageFormControl.hasError('required')">
          {{tooltipEmail1}} <strong>{{tooltipEmail2}}</strong>
        </mat-error>
      </mat-form-field>
      <div *ngIf="hideSubmit()">
        <button mat-flat-button (click)="submitForm()">{{btnSubmit}}</button>
      </div>
    </form>
  </div>
</div>

