<div (window:resize)="onResize($event)" class="mainDiv">
  <section class="main-section" id = "main-section">
    <div class="cover">
    <app-owner-name></app-owner-name>
    <app-owner-avatar></app-owner-avatar>
    <app-owner-title [selectedLang] = "selectedLang" ></app-owner-title>
  </div>
  <div class="bio-section">
    <app-owner-bio [selectedLang] = "selectedLang" ></app-owner-bio>
  </div>
  <div class= "main-header">
    <app-language-selector *ngIf="languageOnNavBarNot" [attachToNavBar]="languageOnNavBar" (selectedLang) = "setLanguage($event)"></app-language-selector>
  </div>
</section>
<nav class= "navbar-section" [class]= "setNavBarStickyClass()" id="navBar">
  <div class="stickyNavBar">
    <app-navbar [selectedLang] = "selectedLang"></app-navbar>
  </div>
  <div class="stickyLanguage">
    <app-language-selector *ngIf="languageOnNavBar" [attachToNavBar]="languageOnNavBar" (selectedLang) = "setLanguage($event)"></app-language-selector>
  </div>
</nav>
<section class="skils-section" id = "skils-section">
  <!--<app-education [selectedLang] = "selectedLang"></app-education>-->
  <app-knowledge [selectedLang] = "selectedLang"></app-knowledge>
</section>
<section class="jobs-section" id = "jobs-section">
  <app-jobs [selectedLang] = "selectedLang"></app-jobs>
</section>
<section class="footerSection" id = "footerSection">
  <div class="mail-form-section">
    <app-mail-form [selectedLang] = "selectedLang"></app-mail-form>
  </div>
  <div class="contact-section">
      <app-owner-contact [selectedLang] = "selectedLang"></app-owner-contact>
  </div>
</section>
</div>
